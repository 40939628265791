exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/AboutUs.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-financials-js": () => import("./../../../src/pages/Financials.js" /* webpackChunkName: "component---src-pages-financials-js" */),
  "component---src-pages-funding-js": () => import("./../../../src/pages/Funding.js" /* webpackChunkName: "component---src-pages-funding-js" */),
  "component---src-pages-grantees-js": () => import("./../../../src/pages/Grantees.js" /* webpackChunkName: "component---src-pages-grantees-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-trustees-js": () => import("./../../../src/pages/Trustees.js" /* webpackChunkName: "component---src-pages-trustees-js" */),
  "slice---src-slices-banner-js": () => import("./../../../src/slices/banner.js" /* webpackChunkName: "slice---src-slices-banner-js" */),
  "slice---src-slices-footer-js": () => import("./../../../src/slices/footer.js" /* webpackChunkName: "slice---src-slices-footer-js" */),
  "slice---src-slices-header-js": () => import("./../../../src/slices/header.js" /* webpackChunkName: "slice---src-slices-header-js" */)
}

